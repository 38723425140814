




































































































































































































































































































import Base from '@/mixins/Base.vue';
import { ISurvey, ISurveyResponse } from '@/interfaces/survey';
import { IFlow } from '@/interfaces/flow';

const component = Base.extend({
    data() {
        return {
            isAddFlowModalActive: false,
            newFlows: [] as Array<IFlow>,
            errors: false,
            activeTab: 'general',
            survey: undefined as any | ISurvey,

        };
    },
    mounted() {
        this.getSurvey();
    },
    methods: {
        addFlows() {
            if (!this.survey.flows) this.survey.flows = [];

            this.newFlows.forEach((f:IFlow) => {
                this.survey.flows.push(f);
            });

            this.newFlows = [];
            this.isAddFlowModalActive = false;
        },
        getSurvey(): void {
            this.get<ISurveyResponse>(`surveys/${this.$route.params.id}`)
                .then(({ data }) => {
                    this.survey = data.data;
                });
        },
        saveSurvey(): void {
            this.put<ISurveyResponse>(`surveys/${this.$route.params.id}`, {
                ...this.survey,
                country_id: this.survey.country ? this.survey.country.id : null,
                region_id: this.survey.region ? this.survey.region.id : null,
            }).then(({ data }) => {
                this.survey = data.data;
                this.$emit('success', { message: 'survey updated' });
            });
        },

    },
});

export default component;
